import MediaOverlayControlsComponent from '@wix/thunderbolt-elements/src/components/VideoBoxContainer/VideoBoxPlay/viewer/VideoBoxPlay';


const MediaOverlayControls = {
  component: MediaOverlayControlsComponent
};


export const components = {
  ['MediaOverlayControls']: MediaOverlayControls
};


// temporary export
export const version = "1.0.0"
